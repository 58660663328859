import React, { useContext, useEffect, useRef, useState } from "react";
import SpacesMenu from "./SpacesMenu";
import ProfileMenu from "./ProfileMenu";

import enoLogo from "../../assets/EnolyticsLarge_WhiteLetters_Transparent@2x.png";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/Data";
import getIframeLinkByNavlink from "../../utils/getIframeLinkByNavlink";

export default function HeaderV2({ space }) {
  const navigate = useNavigate();
  const { setV2RouteSelected, v2Routes } = useContext(DataContext);
  const [openMenu, setOpenMenu] = useState(null);
  const navbarRef = useRef(null);

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function getIframeLink(navlink) {
    const stringStaging = window.location.origin.includes("staging") ? "_staging" : "";
    let result = getIframeLinkByNavlink(v2Routes[`dtc_routes${stringStaging}`], navlink);
    if (result) {
      return { ...result, navlink: "" };
    }
  }

  return (
    <>
      <nav
        className="bg-[#26282f] border-t-0 border-l-0 border-r-0 border-b-[0.5px] border-solid border-white"
        ref={navbarRef}
      >
        <div className="max-w-full mx-auto pl-16 pr-4">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <img
                data-testid="logo"
                className="h-12 w-auto logo-image"
                src={enoLogo}
                alt="Enolytics"
                onClick={() => {
                  const obj = getIframeLink("kpi_dashboard");
                  setV2RouteSelected(obj);
                  navigate(space);
                }}
              />
              {/* <HeadwayWidget /> */}
            </div>
            <div className="flex items-center">
              <SpacesMenu isOpen={openMenu === "spaces"} toggleMenu={() => toggleMenu("spaces")} />
              {/* <NotificationsMenu isOpen={openMenu === "notifications"} toggleMenu={() => toggleMenu("notifications")} /> */}
              <ProfileMenu isOpen={openMenu === "profile"} toggleMenu={() => toggleMenu("profile")} />
            </div>
          </div>
        </div>
      </nav>
      <div className="h-2 bg-main"></div>
    </>
  );
}
